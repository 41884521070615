/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'napgHZmDUAXFf7GDKTsw95.png': { uri: '/images/napgHZmDUAXFf7GDKTsw95.png' },
'gxLhgNrrTwvdG8STUmJ4MG.jpg': { uri: '/images/gxLhgNrrTwvdG8STUmJ4MG.jpg' },
'apzvRv2rrN69xy41RJvmgB.png': { uri: '/images/apzvRv2rrN69xy41RJvmgB.png' },
'7VuYcfVHWJdtaiyhgBtwSi.png': { uri: '/images/7VuYcfVHWJdtaiyhgBtwSi.png' },
'tqEWi9rhgYWLca5JvSchB6.png': { uri: '/images/tqEWi9rhgYWLca5JvSchB6.png' },
'3M5szp8dedJZivnxdpEGVD.png': { uri: '/images/3M5szp8dedJZivnxdpEGVD.png' },
'gEz8AtYVifHPDfG6MSSdKx.png': { uri: '/images/gEz8AtYVifHPDfG6MSSdKx.png' },
'4xSdahDQTBacrnaRmga8ab.png': { uri: '/images/4xSdahDQTBacrnaRmga8ab.png' },
'3vjr5VQrM3hPoow4QuhF6V.png': { uri: '/images/3vjr5VQrM3hPoow4QuhF6V.png' },
'4HSYo1dAGVPhoQExNeavaR.png': { uri: '/images/4HSYo1dAGVPhoQExNeavaR.png' },
'aMjzXFuJK2nsiEgCbNENKh.png': { uri: '/images/aMjzXFuJK2nsiEgCbNENKh.png' },
'iDBWZUguFnEfm7Pe9BvAVG.png': { uri: '/images/iDBWZUguFnEfm7Pe9BvAVG.png' },
'pzxJLNQMkmJMDmrKcJsoSH.png': { uri: '/images/pzxJLNQMkmJMDmrKcJsoSH.png' },
'rSyM86X7RUijsWwWqYaiuZ.png': { uri: '/images/rSyM86X7RUijsWwWqYaiuZ.png' },
'49Ybn3n9jeucdkr8c6yguC.png': { uri: '/images/49Ybn3n9jeucdkr8c6yguC.png' },
'eaFnaU2ccgjZvKgPJ7hn4p.png': { uri: '/images/eaFnaU2ccgjZvKgPJ7hn4p.png' },
'rC7UUVhfHb7UYNB69kBST8.png': { uri: '/images/rC7UUVhfHb7UYNB69kBST8.png' },
'vMTJcJftvuQyJiDWBparbx.png': { uri: '/images/vMTJcJftvuQyJiDWBparbx.png' },
'5D5y3TXWLsL3djxVhiGtHs.png': { uri: '/images/5D5y3TXWLsL3djxVhiGtHs.png' },
'g3LP9GsAAmBT7nHGZxQxdZ.png': { uri: '/images/g3LP9GsAAmBT7nHGZxQxdZ.png' },
'3LvUag5MGqvDUJhBJdQ1Gm.png': { uri: '/images/3LvUag5MGqvDUJhBJdQ1Gm.png' },
'9sRnKqgeQQbctgckDLNXfj.png': { uri: '/images/9sRnKqgeQQbctgckDLNXfj.png' },
'baoGdGKVw1d3L1KZBRvHnR.png': { uri: '/images/baoGdGKVw1d3L1KZBRvHnR.png' },
'oFkUnTAbqjGPgE1XdSjfYL.png': { uri: '/images/oFkUnTAbqjGPgE1XdSjfYL.png' },
'fqb1vTjN9UqiJsLAVxc1MH.png': { uri: '/images/fqb1vTjN9UqiJsLAVxc1MH.png' },
'fkGk6DW58xa4nvLbwV143a.png': { uri: '/images/fkGk6DW58xa4nvLbwV143a.png' },
'cPudoL83Jwd64cFZJtLo1K.png': { uri: '/images/cPudoL83Jwd64cFZJtLo1K.png' },
'uaaPWNAscPvN8E9XiBoosG.png': { uri: '/images/uaaPWNAscPvN8E9XiBoosG.png' },
'kKTFuQcnGGezswJkuyKFGH.png': { uri: '/images/kKTFuQcnGGezswJkuyKFGH.png' },
'3twEAkhHLpioXJ2rcfhF95.png': { uri: '/images/3twEAkhHLpioXJ2rcfhF95.png' },
'1LsKA3hZJY85CgGpxrQSHt.png': { uri: '/images/1LsKA3hZJY85CgGpxrQSHt.png' },
'cMGhPSNxZ9GcHA7Py6w61P.png': { uri: '/images/cMGhPSNxZ9GcHA7Py6w61P.png' },
'syMq59okqHiV57kkAWLZVX.png': { uri: '/images/syMq59okqHiV57kkAWLZVX.png' },
'qYjg5ZrBypFcTQH35UBR8f.png': { uri: '/images/qYjg5ZrBypFcTQH35UBR8f.png' },
't2QjP9gEQaN5ZJUjmFiAPi.webp': { uri: '/images/t2QjP9gEQaN5ZJUjmFiAPi.webp' },
'3bQbSznwS6SRKvsPvySu6x.png': { uri: '/images/3bQbSznwS6SRKvsPvySu6x.png' },
'9VGANg4znKqrBdWMmKnVVn.png': { uri: '/images/9VGANg4znKqrBdWMmKnVVn.png' },
'vgo1sAE4VvpXPdp5CsBA75.png': { uri: '/images/vgo1sAE4VvpXPdp5CsBA75.png' },
'mfGiXw5wY4p8dyz4851Ed3.png': { uri: '/images/mfGiXw5wY4p8dyz4851Ed3.png' },
'is9R1nw9CSHbLZJWbMjHfh.jpg': { uri: '/images/is9R1nw9CSHbLZJWbMjHfh.jpg' },
'5ghchaeMHBR128zfLR8iAD.jpg': { uri: '/images/5ghchaeMHBR128zfLR8iAD.jpg' },
'iKYouAjdQpSoKLgYWFu2C8.jpg': { uri: '/images/iKYouAjdQpSoKLgYWFu2C8.jpg' },
'2gsZAC1UfHzGAKahVCumuS.png': { uri: '/images/2gsZAC1UfHzGAKahVCumuS.png' },
'4rjisscN8CzRKBVTv8h2zN.jpg': { uri: '/images/4rjisscN8CzRKBVTv8h2zN.jpg' },
'2UZJDuugEBUrUmAjECT9PN.jpg': { uri: '/images/2UZJDuugEBUrUmAjECT9PN.jpg' },
'57Vegwk8FPh1Tvz5aRGmsG.jpg': { uri: '/images/57Vegwk8FPh1Tvz5aRGmsG.jpg' },
'tPjwpydr2TwpsRYCsRSNxA.jpg': { uri: '/images/tPjwpydr2TwpsRYCsRSNxA.jpg' }
}

export default imageStaticSourcesByFileName
